import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import { navigate } from 'gatsby';
import React from 'react';
import Redirect from '@shoreag/base/Redirect';
import { FORM_ERROR } from 'final-form';
import { Field, Form as FinalForm } from 'react-final-form';
import { Auth } from 'aws-amplify';
import { composeValidations, minLength, required } from '@shoreag/validations';
import { get } from 'lodash';
import Button from '@shoreag/base/Button';
import QRCode from 'react-qr-code';
import AuthContainer from '../../containers/AuthContainer';
import FormError from '../../components/FormError';
import LoginIllustration from '../../images/illustrations/login.svg';
import Logo from '../../components/Logo';
import SubmitButton from '../../components/SubmitButton';
import config from '../../config.json';

const LoginPage = () => {
  return (
    <AuthContainer>
      {({
        authCode,
        confirmSignIn,
        mfaSetup,
        newPasswordRequired,
        signIn,
        softwareTokenMfa,
      }) => {
        return (
          <Box
            display={{ lg: 'flex' }}
            height={{ lg: '100%' }}
            minHeight="750px"
            sx={{ position: [null, null, null, null, 'absolute'] }}
            width={{ lg: '100%' }}
          >
            <Redirect to="/complete-signup" when={newPasswordRequired} />
            <Box
              alignItems="center"
              bg={{ lg: 'white' }}
              display="flex"
              height={{ lg: '100%' }}
              justifyContent={{ _: 'center', lg: 'flex-end' }}
              width={{ lg: '50%' }}
            >
              <Logo
                sx={{
                  left: '3rem',
                  mt: '3rem',
                  position: [null, null, null, null, 'absolute'],
                  top: '0',
                  width: '10rem',
                }}
              />
              <Box
                sx={{
                  display: ['none', null, null, null, 'block'],
                  maxWidth: '650px',
                  position: 'relative',
                  right: '-3rem',
                  top: '2rem',
                  width: '100%',
                }}
              >
                <LoginIllustration width="100%" />
              </Box>
            </Box>
            <Box
              alignItems="center"
              bg={{ lg: 'primary' }}
              display="flex"
              height={{ lg: '100%' }}
              justifyContent="center"
              px={5}
              py={8}
              width={{ lg: '50%' }}
            >
              <FinalForm
                onSubmit={async (values) => {
                  try {
                    if (mfaSetup || softwareTokenMfa) {
                      await confirmSignIn(values);
                    } else {
                      await signIn(values);
                    }
                  } catch (e) {
                    if (e.code === 'PasswordResetRequiredException') {
                      await navigate(
                        `/password-reset?username=${encodeURIComponent(
                          values.username
                        )}`
                      );
                    } else {
                      return { [FORM_ERROR]: e.message };
                    }
                  }
                }}
                render={(formContext) => (
                  <Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
                    <Box
                      as="h1"
                      color={{ lg: 'white' }}
                      fontSize={7}
                      mb={6}
                      textAlign={{ _: 'center', lg: 'left' }}
                    >
                      Login
                    </Box>
                    <form onSubmit={formContext.handleSubmit}>
                      {mfaSetup || softwareTokenMfa ? (
                        <>
                          {authCode ? (
                            <Box
                              sx={{
                                bg: 'white',
                                border: '10px solid white',
                                width: '200px',
                              }}
                            >
                              <QRCode size={180} value={authCode} />
                            </Box>
                          ) : null}
                          <Field
                            component={Input}
                            label="Verification Code"
                            name="token"
                            placeholder="Enter 6 Digit verification code"
                            validate={composeValidations(required)}
                          />
                          <FormError>{formContext.submitError}</FormError>
                          <SubmitButton
                            data-cy="submit"
                            submitting={formContext.submitting}
                            width={{ lg: '100%' }}
                          >
                            Submit
                          </SubmitButton>
                        </>
                      ) : (
                        <>
                          <Field
                            component={Input}
                            data-cy="email"
                            label="Username or Email"
                            name="username"
                            validate={composeValidations(required)}
                          />
                          <Field
                            component={Input}
                            data-cy="password"
                            label="Password"
                            name="password"
                            type="password"
                            validate={composeValidations(
                              minLength(config.minPasswordLength),
                              required
                            )}
                          />
                          <FormError>{formContext.submitError}</FormError>
                          <Button
                            link={`/password-reset${
                              get(formContext, 'values.username', '')
                                ? `?username=${encodeURIComponent(
                                    formContext.values.username
                                  )}`
                                : ''
                            }`}
                            simple
                            sx={{
                              float: 'right',
                              fontSize: 2,
                              mt: 2,
                            }}
                          >
                            Forgot password?
                          </Button>
                          <SubmitButton
                            data-cy="submit"
                            submitting={formContext.submitting}
                            width={{ lg: '100%' }}
                          >
                            Sign In
                          </SubmitButton>
                          <Box as="hr" mt={5} />
                          <Button
                            onClick={() =>
                              Auth.federatedSignIn({
                                provider: config.cognitoProvider.provider,
                              })
                            }
                            sx={{
                              bg: 'accentDark',
                              mt: 5,
                              width: '100%',
                            }}
                          >
                            OKTA SSO
                          </Button>
                        </>
                      )}
                    </form>
                  </Box>
                )}
              />
            </Box>
          </Box>
        );
      }}
    </AuthContainer>
  );
};

export default LoginPage;
